import React, {
    useState, useEffect, useCallback, useContext, useMemo
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { withRouter } from 'react-router-dom';
import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { useAuthentication } from 'gw-digital-auth-react';
import { ServiceRequestService } from 'gw-capability-servicerequest';
import {
    AcceptWorkActionPopup,
    CommonWorkActionPopup,
    AddQuoteModal,
    DelayWorkActionPopup,
    AddInvoicePopup,
    WithdrawInvoicePopup,
    ReplyMessagePopup,
    CreateNewMessagePopup
} from 'gw-capability-servicerequest-react';
import {
    Loader, ModalNextProvider, FormattedDate, Tooltip, Button
} from '@jutro/components';
import { Link, Currency as CurrencyField } from 'gw-components-platform-react';
import { FileUtil } from 'gw-portals-util-js';
import appConfig from 'app-config';
import metadata from './ServiceRequestDetailsPage.metadata.json5';
import styles from './ServiceRequestDetailsPage.module.scss';
import messages from './ServiceRequestDetailsPage.messages';

function ServiceRequestDetailsPage(props) {
    const { match } = props;
    const { params } = match;
    const { serviceRequestNumber } = params;
    const [serviceRequestPID, setRequestPID] = useState('');
    const { authHeader } = useAuthentication();
    const [isLoading, setLoadingState] = useState(false);
    const [serviceItems, setServiceItems] = useState({});
    const [serviceDetails, setServiceDetails] = useState({ otherOperations: [] });
    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);

    const [fileFormatMsg, setFileFormatMsg] = useState('');
    // const [view, updateView] = useState(VIEWS.hideFileSizeWarningView);
    const { docUploadMap, fileUploadLinkSite } = appConfig;
    const [isAddInvoicePopupOpen, setAddInvoicePopupOpen] = useState(false);
    const [isAddQuotePopupOpen, setAddQuotePopupOpen] = useState(false);
    const PRIMARY_PHONE_TYPE_MAP = {
        home: 'homeNumber',
        mobile: 'cellNumber',
        work: 'workNumber'
    };

    const readValue = useCallback((id, path) => {
        if (path === 'otherOperations') {
            return _.get(serviceDetails, path);
        }
        return _.get(serviceItems, path);
    }, [serviceDetails, serviceItems]);

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (1 + date.getMonth()).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${month}${day}${year}`;
    };

    const lookupActionNameMessageKey = useCallback(
        (action) => {
            const { isReviseQuote } = serviceItems;
            const { quoteExists } = serviceDetails;
            if (action) {
                if (action === 'addquote') {
                    if (isReviseQuote || quoteExists) {
                        return translator(messages.actionReviseQuote);
                    }
                    return translator(messages.actionAddQuote);
                }
                if (action === 'vendordelayed') { // not an operation in CC, but an available action
                    return translator(messages.actionReportADelay);
                }
                return translator(messages[action]);
            }
            return null;
        }, [serviceDetails, serviceItems, translator]
    );

    const getServiceRequestDetails = useCallback(
        () => {
            setLoadingState(true);
            ServiceRequestService.getServiceRequestDetails(serviceRequestNumber, authHeader)
                .then((response) => {
                    const serviceData = {};
                    setServiceItems(response);
                    setRequestPID(response.publicID);
                    serviceData.isOverdue = response.isOverdue;
                    serviceData.quoteExists = !_.isUndefined(response.latestQuote);
                    const hasDefaultOperation = !_.isUndefined(response.defaultOperation);
                    serviceData.firstOperation = hasDefaultOperation
                        ? response.defaultOperation
                        : _.head(response.otherOperations);
                    serviceData.otherOperations = hasDefaultOperation
                        ? response.otherOperations
                        : _.tail(response.otherOperations);
                    serviceData.dueDate = response.serviceDateApplies
                        ? response.expectedServiceCompletionDate
                        : response.expectedQuoteCompletionDate;

                    const customerContactPreferredNumberType = _.get(PRIMARY_PHONE_TYPE_MAP,
                        response.customerContact.primaryPhoneType);
                    const claimHandlerPreferredNumberType = _.get(PRIMARY_PHONE_TYPE_MAP,
                        response.claimHandler.primaryPhoneType);
                    serviceData.customerContactPreferredNumber = _.get(response.customerContact,
                        customerContactPreferredNumberType);
                    serviceData.claimHandlerPreferredNumber = _.get(response.claimHandler,
                        claimHandlerPreferredNumberType);

                    serviceData.invoiceTotal = {
                        amount: response.outstandingInvoiceAmount.amount
                            + response.paymentsMadeAmount.amount,
                        currency: response.outstandingInvoiceAmount.currency
                    };
                    serviceData.sortedHistory = _.sortBy(response.history, 'sequence');
                    if (response.additionalInstructions
                            && response.additionalInstructions.length > 100) {
                        serviceData.showFullInstructions = false;
                        serviceData.truncatedInstructions = _.truncate(
                            response.additionalInstructions, { length: 100 }
                        );
                    }
                    if (response.canDelay) {
                        serviceData.otherOperations.push('vendordelayed'); // Not a real SR Operation - but needed to reflect ability to delay date in CC
                    }
                    // translator(messages.fileSizeErrorMessage, {value1: serviceItems.edgeDocumentUploadSize, value2: serviceItems.edgeDocumentUploadSize }),
                    // const formatMsg = translator(messages.fileFormatMessage, {value1: claimID, value2: serviceRequestNumber, value3:})
                    const formatMsg = translator(messages.fileFormatMessage, {
                        value1: response.claimID, value2: serviceRequestNumber, value3: response.vendor.displayName, value4: formatDate(new Date())
                    });
                    setFileFormatMsg(formatMsg);
                    setServiceDetails(serviceData);
                })
                .finally(() => {
                    setLoadingState(false);
                });
        }, [PRIMARY_PHONE_TYPE_MAP, authHeader, serviceRequestNumber]
    );

    const handleAcceptWork = useCallback(
        (actionData) => {
            const yyyyddmm = _.split(actionData.completionDate, '-');
            // eslint-disable-next-line no-param-reassign
            actionData.completionDate_PAExt.year = _.toInteger(yyyyddmm[0]);
            // eslint-disable-next-line no-param-reassign
            actionData.completionDate_PAExt.month = _.toInteger(yyyyddmm[1]) - 1;
            // eslint-disable-next-line no-param-reassign
            actionData.completionDate_PAExt.day = _.toInteger(yyyyddmm[2]);

            ServiceRequestService.acceptWork(actionData, authHeader)
                .then(getServiceRequestDetails);
        }, [authHeader, getServiceRequestDetails]
    );

    const handleCompleteWork = useCallback(
        () => {
            ServiceRequestService.completeWork(serviceRequestNumber, authHeader)
                .then(getServiceRequestDetails);
        }, [authHeader, getServiceRequestDetails, serviceRequestNumber]
    );

    const handleDeclineWork = useCallback(
        (actionData) => {
            ServiceRequestService.declineWork(actionData, authHeader)
                .then(getServiceRequestDetails);
        }, [authHeader, getServiceRequestDetails]
    );

    const handleCancelWork = useCallback(
        (actionData) => {
            ServiceRequestService.cancelServiceRequest(actionData, authHeader)
                .then(getServiceRequestDetails);
        }, [authHeader, getServiceRequestDetails]
    );

    const handlePauseWork = useCallback(
        (actionData) => {
            ServiceRequestService.reportBlocked(actionData, authHeader)
                .then(getServiceRequestDetails);
        }, [authHeader, getServiceRequestDetails]
    );

    const handleDelayWork = useCallback(
        (actionData) => {
            ServiceRequestService.reportDelay(actionData, authHeader)
                .then(getServiceRequestDetails);
        }, [authHeader, getServiceRequestDetails]
    );

    const handleAddQuote = useCallback(
        (quoteVM) => {
            ServiceRequestService.addOrReplaceQuote(
                serviceItems.publicID, quoteVM.value, authHeader
            ).then(getServiceRequestDetails);
        }, [authHeader, getServiceRequestDetails, serviceItems]
    );

    const handleAddInvoice = useCallback(
        (invoiceVM) => {
            ServiceRequestService.addInvoice(
                serviceItems.publicID, invoiceVM.value, authHeader
            ).then(getServiceRequestDetails);
        }, [authHeader, getServiceRequestDetails, serviceItems]
    );

    const externalLinkFunc = useCallback(() => {
        const uploadlink = _.find(docUploadMap, (o) => { return _.startsWith(window.location, o.key); });
        window.open(uploadlink.value, 'mywin', 'left=20,top=20,width=900,height=500,toolbar=1,resizable=0');
        return false;
    });

    const handleUploadDocument = useCallback(async (file) => {
        // setLoadingState(true);
        const documentsArray = _.get(serviceItems, 'documents', []);
        const existingDocuments = serviceItems.documents;
        // Check File type being uploaded and allow only file types not included in the array.
        const fileExtensions = new Set(['exe', 'jar', 'zip']);
        if (fileExtensions.has(file.name.split('.').pop())) {
            ModalNextProvider.showAlert({
                title: translator(messages.fileSizeErrorTitle),
                message: translator(messages.fileTypeValidationMessage),
                status: 'warning',
                icon: 'mi-error-outline'
            });
            return;
        }
        if (file && file.size / (1024 * 1024) > serviceItems.edgeDocumentUploadSize) {
            // updateView(VIEWS.fileSizeWarningView)

            ModalNextProvider.showAlert({
                title: translator(messages.fileSizeErrorTitle),
                message: translator(messages.fileSizeErrorMessage, { value1: serviceItems.edgeDocumentUploadSize, value2: serviceItems.edgeDocumentUploadSize }),
                status: 'warning',
                icon: 'mi-error-outline'
            });
            return;
        }
        const documentMetadataTemplate = {
            // serviceRequestId: serviceRequestNumber,
            serviceRequestId: serviceRequestPID,
            name: FileUtil.checkFileName(file.name, [...existingDocuments, ...documentsArray]),
            mimeType: file.type,
            sessionID: await ServiceRequestService.generateUploadToken(authHeader)
        };

        ServiceRequestService.uploadDocument(file, documentMetadataTemplate, authHeader)
            .then(getServiceRequestDetails)
            .catch((error) => {
                ModalNextProvider.showAlert({
                    title: translator(messages.errorTitle),
                    message: translator(messages.errorMessage),
                    status: 'error',
                    icon: 'mi-error-outline'
                });
            }).finally(() => {
                // setLoadingState(false);
                // updateView(false)
            });
    });

    const handleWithdrawInvoice = useCallback(
        (withdrawInvoiceVM) => {
            ServiceRequestService.withdrawInvoice(
                withdrawInvoiceVM.value, authHeader
            ).then(getServiceRequestDetails);
        }, [authHeader, getServiceRequestDetails]
    );

    const handleResumeWork = useCallback(
        () => {
            ServiceRequestService.resumeWork(serviceRequestNumber, authHeader)
                .then(getServiceRequestDetails);
        }, [authHeader, getServiceRequestDetails, serviceRequestNumber]
    );

    const performOperation = useCallback(
        (otherOperation) => {
            let actionProps = {
                serviceRequest: serviceItems,
                viewModelService: viewModelService
            };
            switch (otherOperation) {
                case 'specialistacceptedwork':
                    ModalNextProvider.showModal(
                        <AcceptWorkActionPopup {...actionProps} />
                    )
                        .then(handleAcceptWork)
                        .catch(_.noop);
                    break;
                case 'specialistcompletedwork':
                    ModalNextProvider.showConfirm({
                        title: translator(messages.actionTitleCompleteWork),
                        message: translator(messages.actionMessageCompleteWork),
                        confirmButtonText: translator(messages.actionButtonCompleteWork),
                        cancelButtonText: translator(messages.actionButtonCancel)
                    })
                        .then(
                            (res) => {
                                if (res === 'confirm') {
                                    handleCompleteWork();
                                }
                            }
                        )
                        .catch(_.noop);
                    break;
                case 'specialistdeclined':
                    actionProps = {
                        ...actionProps,
                        modalTitle: translator(messages.actionTitleDeclineWork),
                        modalMessage: translator(messages.actionMessageDeclineWork),
                        defaultButtonLabel: translator(messages.actionButtonDeclineWork),
                        cancelButtonLabel: translator(messages.actionButtonCancel)
                    };
                    ModalNextProvider.showModal(
                        <CommonWorkActionPopup {...actionProps} />
                    )
                        .then(handleDeclineWork)
                        .catch(_.noop);
                    break;
                case 'specialistcanceled':
                    actionProps = {
                        ...actionProps,
                        modalTitle: translator(messages.actionTitleCancelWork),
                        modalMessage: translator(messages.actionMessageCancelWork),
                        defaultButtonLabel: translator(messages.actionButtonCancelWork),
                        cancelButtonLabel: translator(messages.actionButtonCancel)
                    };
                    ModalNextProvider.showModal(
                        <CommonWorkActionPopup {...actionProps} />
                    )
                        .then(handleCancelWork)
                        .catch(_.noop);
                    break;
                case 'specialistwaiting':
                    actionProps = {
                        ...actionProps,
                        modalTitle: translator(messages.actionTitlePauseWork),
                        modalMessage: translator(messages.actionMessagePauseWork),
                        defaultButtonLabel: translator(messages.actionButtonPauseWork),
                        cancelButtonLabel: translator(messages.actionButtonCancel)
                    };
                    ModalNextProvider.showModal(
                        <CommonWorkActionPopup {...actionProps} />
                    )
                        .then(handlePauseWork)
                        .catch(_.noop);
                    break;
                case 'addquote':
                    setAddQuotePopupOpen(true);
                    // ModalNextProvider.showModal(
                    //     <AddQuoteModal
                    //         quoteValue={serviceItems.latestQuote}
                    //         serviceRequestID={serviceItems.publicID}
                    //         existingDocuments={serviceItems.documents}
                    //     />
                    // )
                    //     .then(handleAddQuote)
                    //     .catch(_.noop);
                    break;
                case 'addinvoice':
                    setAddInvoicePopupOpen(true);
                    // ModalNextProvider.showModal(
                    //     <AddInvoicePopup
                    //         serviceRequestID={serviceItems.publicID}
                    //         existingDocuments={serviceItems.documents}
                    //     />
                    // )
                    //     .then(handleAddInvoice)
                    //     .catch(_.noop);
                    break;
                case 'vendordelayed':
                    ModalNextProvider.showModal(
                        <DelayWorkActionPopup {...actionProps} />
                    )
                        .then(handleDelayWork)
                        .catch(_.noop);
                    break;
                case 'specialistresumedwork':
                    ModalNextProvider.showConfirm({
                        title: translator(messages.actionTitleResumeWork),
                        message: translator(messages.actionMessageResumeWork),
                        confirmButtonText: translator(messages.actionButtonResumeWork),
                        cancelButtonText: translator(messages.actionButtonCancel)
                    })
                        .then(
                            (res) => {
                                if (res === 'confirm') {
                                    handleResumeWork();
                                }
                            }
                        )
                        .catch(_.noop);
                    break;
                default:
                    break;
            }
        }, [
            handleAcceptWork,
            handleAddQuote,
            handleAddInvoice,
            handleCompleteWork,
            handleDeclineWork,
            handlePauseWork,
            handleDelayWork,
            handleCancelWork,
            handleResumeWork,
            serviceItems,
            translator,
            viewModelService,
            isAddInvoicePopupOpen,
            isAddQuotePopupOpen
        ]
    );

    const generateActionItemOverrides = useMemo(
        () => {
            const { otherOperations } = serviceDetails;
            const actionOverrides = otherOperations.map((otherOperation, index) => {
                return {
                    [`actionItem${index}`]: {
                        content: lookupActionNameMessageKey(otherOperation),
                        onClick: () => performOperation(otherOperation)
                    }
                };
            });
            return Object.assign({}, ...actionOverrides);
        }, [lookupActionNameMessageKey, performOperation, serviceDetails]
    );

    const mailToTrigger = useCallback((event, email) => {
        event.preventDefault();
        window.location = `mailto:${email}`;
    }, []);

    const renderEmailLink = useCallback((emailID) => {
        return (
            <Link className={styles.link} onClick={(e) => mailToTrigger(e, emailID)} href="/">
                {emailID}
            </Link>
        );
    }, [mailToTrigger]);

    const phoneTrigger = useCallback((event, phoneNumber) => {
        event.preventDefault();
        window.location = `tel:${phoneNumber}`;
    }, []);

    const getDateModified = useCallback((items, index, { path: property }) => {
        if (typeof items[property] === 'undefined') {
            return '';
        }
        return <FormattedDate value={new Date(items[property])} format="short" />;
    }, []);

    const renderPhoneLink = useCallback((phone) => {
        return (
            <Link className={styles.link} onClick={(e) => phoneTrigger(e, phone)} href="/">
                {phone}
            </Link>
        );
    }, [phoneTrigger]);

    const renderAddInvoiceLabel = useCallback((invoices) => {
        if(invoices !== undefined && invoices.length > 0){
            return 'Add Additional Invoice';
        }
        return 'Add Invoice'
    }, []);

    const getCellPremium = useCallback((rowData, rowIndex) => {
        return (
            <CurrencyField
                id={`totalamount_${rowIndex}`}
                value={rowData.totalAmount}
                readOnly
            />
        );
    }, []);

    const addNewMessage = useCallback(() => {
        ModalNextProvider.showModal(
            <CreateNewMessagePopup
                serviceRequestNumber={serviceRequestNumber}
                authHeader={authHeader}
            />
        ).then(getServiceRequestDetails);
    }, [authHeader, getServiceRequestDetails, serviceRequestNumber]);

    const replyMessage = useCallback((messageData) => {
        ModalNextProvider.showModal(
            <ReplyMessagePopup
                serviceRequestNumber={serviceRequestNumber}
                serviceItems={serviceItems}
                messageData={messageData}
            />
        ).then(getServiceRequestDetails);
    }, [getServiceRequestDetails, serviceItems, serviceRequestNumber]);

    const getInvoiceStatus = useCallback((rowData) => {
        return translator({
            id: `typekey.ServiceRequestInvoiceStatus.${rowData.status}`,
            defaultMessage: rowData.status
        });
    }, [translator]);

    const withdrawInvoice = useCallback((invoiceData) => {
        ModalNextProvider.showModal(
            <WithdrawInvoicePopup invoiceID={invoiceData.publicID} />
        ).then(handleWithdrawInvoice);
    }, [handleWithdrawInvoice]);

    // const getDocumentDownloadLink = useCallback(
    //     (item) => {
    //         const { publicID, sessionID } = item;
    //         const linkHref = ServiceRequestService.downloadDocumentLink(publicID, sessionID);
    //         return (
    //             <Link className={styles.link} href={linkHref}>
    //                 {item.name}
    //             </Link>
    //         );
    //     },
    //     []
    // );

    const addNewInvoice = useCallback(() => {
        ModalNextProvider.showModal(
            <AddInvoicePopup
                serviceRequestID={serviceItems.publicID}
                existingDocuments={serviceItems.documents}
            />
        ).then(handleAddInvoice);
    }, [handleAddInvoice, serviceItems]);

    const getWithdrawButton = useCallback((invoiceData) => {
        if (!invoiceData.canWithdraw) {
            return null;
        }

        return (
            <Button
                id="withdrawButton"
                type="secondary"
                icon="mi-delete"
                onClick={() => withdrawInvoice(invoiceData)}
            />
        );
    }, [withdrawInvoice]);

    useEffect(() => {
        getServiceRequestDetails();
        // disable re render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }
    const primaryPhoneType = PRIMARY_PHONE_TYPE_MAP[_.get(serviceItems, 'customerContact.primaryPhoneType')];

    const override = {
        '@field': {
            labelPosition: 'left'
        },
        serviceRequestsDetailsTitle: {
            content: `${_.get(serviceItems, 'customerContact.displayName')} - ${_.get(serviceItems, 'claimID')}`
        },
        customerContactEmail: {
            content: renderEmailLink(_.get(serviceItems, 'customerContact.emailAddress1'))
        },
        claimHandlerEmail: {
            content: renderEmailLink(_.get(serviceItems, 'claimHandler.emailAddress1'))
        },
        claimHandlerContact: {
            content: renderPhoneLink(_.get(serviceItems, 'claimHandler.cellNumber'))
        },
        customerContactNumber: {
            content: _.get(serviceItems, `customerContact.${primaryPhoneType}`) ? renderPhoneLink(_.get(serviceItems, `customerContact.${primaryPhoneType}`)) : null
        },
        actionsDropdown: {
            visible: serviceDetails.otherOperations.length > 0
        },
        addInvoiceButtonContainer: {
            visible: _.get(serviceItems, 'canAddInvoice')
        },
        addInvoiceButton: {
            content : renderAddInvoiceLabel(_.get(serviceItems, 'invoices'))
        },
        actionDefaultButton: {
            content: lookupActionNameMessageKey(serviceDetails.firstOperation),
            onClick: () => performOperation(serviceDetails.firstOperation),
            visible: !_.isUndefined(serviceDetails.firstOperation)
        },
        ...generateActionItemOverrides,
        messageTable: {
            data: _.get(serviceItems, 'messages'),
            onRowClick: replyMessage
        },
        invoiceTable: {
            data: _.get(serviceItems, 'invoices')
        },
        historyTable: {
            data: _.get(serviceItems, 'history')
        },
        documentTable: {
            data: _.orderBy(_.get(serviceItems, 'documents'), ['dateModified'], ['desc'])
        },
        invoiceTotalAmountColumn: {
            renderCell: getCellPremium
        },
        invoiceStatusColumn: {
            renderCell: getInvoiceStatus
        },
        uploadFileButton: {
            // eslint-disable-next-line max-len
            content: translator(messages.fileUploadSite, { value: serviceItems.edgeDocumentUploadSize })
        },
        helpMessage: {
            content: fileFormatMsg
        },
        addInvoicePopupId: {
            serviceRequestID: serviceItems.publicID,
            existingDocuments: serviceItems.documents,
            onResolve: () => setAddInvoicePopupOpen(false),
            onReject: () => setAddInvoicePopupOpen(false),
            handleNewInvoice: handleAddInvoice,
            isOpen: isAddInvoicePopupOpen
        },
        addQuotePopupId: {
            serviceRequestID: serviceItems.publicID,
            existingDocuments: serviceItems.documents,
            onResolve: () => setAddQuotePopupOpen(false),
            onReject: () => setAddQuotePopupOpen(false),
            handleNewQuote: handleAddQuote,
            isOpen: isAddQuotePopupOpen
        }
        //,
        // documentName: {
        //     onCell: getDocumentDownloadLink
        // }
        // fileSizeWarningContainer: {
        //     visible: view === VIEWS.fileSizeWarningView
        // }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveValue: readValue,
        resolveComponentMap: {
            addInvoicePopup: AddInvoicePopup,
            addQuotePopup: AddQuoteModal
        },
        resolveCallbackMap: {
            getDateModified: getDateModified,
            addNewInvoice: addNewInvoice,
            addNewMessage: addNewMessage,
            getWithdrawButton: getWithdrawButton,
            uploadFn: handleUploadDocument,
            fileUploadLinkClick: externalLinkFunc
        }
    };

    return renderContentFromMetadata(metadata.pageContent, override, resolvers);
}

ServiceRequestDetailsPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            serviceRequestNumber: PropTypes.string
        })
    }).isRequired
};

export default withRouter(ServiceRequestDetailsPage);
