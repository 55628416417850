import React, {
    useState, useEffect, useCallback, useContext, useMemo
} from 'react';
import _ from 'lodash';
import { Link } from 'gw-components-platform-react';
import { withRouter } from 'react-router-dom';
import { TranslatorContext, FormattedDate } from '@jutro/locale';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { ServiceRequestService } from 'gw-capability-servicerequest';
import { useAuthentication } from 'gw-digital-auth-react';
import {
    Loader, ModalNextProvider
} from '@jutro/components';

import metadata from './UserApprovePage.metadata.json5';
import messages from './UserApprovePage.messages';
import styles from './UserApprovePage.module.scss';
import {
     Button
} from '@jutro/components';


const actionsApproveCell = () => {
    return '';
};

const actionsRejectCell = () => {
    return '';
};



const searchUserTableFilter = (filterValue) => (row) => {
    const val = filterValue.toLowerCase();
    return (
       row.firstName.toLowerCase().includes(val) ||
       row.lastName.toLowerCase().includes(val) ||
       row.regStatus.toLowerCase().includes(val) 
    );
};

const searchUserViewTableFilter = (filterValue) => (row) => {
    const val = filterValue.toLowerCase();
    return (
       row.firstName.toLowerCase().includes(val) ||
       row.lastName.toLowerCase().includes(val) ||
       row.regStatus.toLowerCase().includes(val) 
    );
};


function UserApprovePage() {
    const translator = useContext(TranslatorContext);
    const { authHeader } = useAuthentication();
    const [isLoading, setLoadingState] = useState(false);
    const [registerUserData, setRegisterUserData] = useState([]);
    const [registerUserViewData, setRegisterUserViewData] = useState([]);

    const filterRegisterUserItems = useCallback((userItems) => {
        // second condition checks if request has a approved quote attached to it
        const usersData = _.orderBy(userItems.filter((item) => item.regStatus === 'Pending'),['regStatus'],['firstName'], ['lastName']);
        const usersViewData = _.orderBy(userItems.filter((item) => item.regStatus !== 'Pending'),['regStatus'],['firstName'], ['lastName']);
        //const usersViewData = _.orderBy(userItems.filter((item) => item.regStatus !== 'Pending'),['regStatus'],['firstName'], ['lastName']);
        setRegisterUserData(usersData);
        setRegisterUserViewData(usersViewData);
    }, []);


    const revokeDataRow = 
    (newRow) => {
        const newData = [...registerUserData];
        ServiceRequestService.rejectRegisterUser(newRow,authHeader).then(
            filterRegisterUserItems
        ).finally(() => {
        });
    };

// this will apply if requirement change
// 07/02/2021
 const actionsRevokeCell = (registerUserCell) => {
    const { regStatus } = registerUserCell;
    return (
        <Button
            id="withdrawButton{regStatus}"
            type="secondary"
            onClick={() => revokeDataRow(registerUserCell)}>
                {translator(messages.registerUserStatus)}
        </Button>
    );
 };

    const rejectDataRow = useCallback(
        (newRow, rowId) => {
            const newData = [...registerUserData];
            ModalNextProvider.showConfirm({
                title: translator(messages.userrejectTitleCompleteWork),
                message: translator(messages.userrejectMessageCompleteWork),
                confirmButtonText: translator(messages.userrejectButtonCompleteWork),
                cancelButtonText: translator(messages.userrejectButtonCancelWork)
            })
                .then(
                    (res) => {
                        if (res === 'confirm') {
                            ServiceRequestService.rejectRegisterUser(newRow,authHeader).then(
                                filterRegisterUserItems 
                            ).finally(() => {
                            });              
                        }
                    }
                )
                .catch(_.noop);
        },
        []
    );

    const getProcessButton = useCallback((row) => {
        console.log('row:--', row);
          if(row.regStatus === 'Approved'){
              return (
                  <Button
                      className={styles.userActionButton}
                      onClick={() => revokeUserDataRow(row)}
                      >
                      Revoke Access
                  </Button>
              );
          } else {
              return (
                  <Button
                      className={styles.userActionButton}
                      onClick={() => reinstateDataRow(row)}
                      >
                      Reinstate Access
                  </Button>
              );
          }
      },
      []
      );

   
    const reinstateDataRow = useCallback((newRow, rowId) => {
        ModalNextProvider.showConfirm({
            title: translator(messages.userReInstateTitleCompleteWork),
            message: translator(messages.userReInstateMessageCompleteWork),
            confirmButtonText: translator(messages.userReInstateButtonCompleteWork),
            cancelButtonText: translator(messages.userapproveButtonCancelWork)
        }).then((res) => {
            if (res === 'confirm') {
                setLoadingState(true);
                ServiceRequestService.reinstateRegisterUser(newRow, authHeader).then(
                ).finally(() => {
                    ServiceRequestService.getRegisterUser(authHeader).then(
                        filterRegisterUserItems
                        ).finally(() => {
                            setLoadingState(false);
                        })
                });
            }
        }).catch(_.noop);
    }, [ServiceRequestService, authHeader, filterRegisterUserItems, registerUserData, translator]);
    
    const revokeUserDataRow = useCallback((newRow, rowId) => {
        ModalNextProvider.showConfirm({
            title: translator(messages.userRevokeTitleCompleteWork),
            message: translator(messages.userRevokeMessageCompleteWork),
            confirmButtonText: translator(messages.userRevokeButtonCompleteWork),
            cancelButtonText: translator(messages.userapproveButtonCancelWork)
        }).then((res) => {
            if (res === 'confirm') {
                setLoadingState(true);
                ServiceRequestService.revokeRegisterUser(newRow, authHeader).then(
                ).finally(() => {
                    ServiceRequestService.getRegisterUser(authHeader).then(
                        filterRegisterUserItems
                        ).finally(() => {
                            setLoadingState(false);
                        })
                });
            }
        }).catch(_.noop);
    }, [ServiceRequestService, authHeader, filterRegisterUserItems, registerUserData, translator]);
   
   
    const approveDataRow = useCallback(

        (newRow, rowId) => {
            const newData = [...registerUserData];
            ModalNextProvider.showConfirm({
                title: translator(messages.userapproveTitleCompleteWork ),
                message: translator(messages.userapproveMessageCompleteWork),
                confirmButtonText: translator(messages.userapproveButtonCompleteWork),
                cancelButtonText: translator(messages.userapproveButtonCancelWork)
            })
                .then(
                    (res) => {
                        if (res === 'confirm') {
                            ServiceRequestService.approveRegisterUser(newRow,authHeader).then(
                                filterRegisterUserItems 
                            ).finally(() => {
                            });               
                        }
                    }
                )
                .catch(_.noop);

            // ServiceRequestService.approveRegisterUser(newRow,authHeader).then(
            //     filterRegisterUserItems 
            // ).finally(() => {
            // });
            // leave these code here in case requirement change
            // 07/02/2021             
            //            const index = getIndexByRowId(registerUserData, rowId);
            //            newData.splice(index, 1, newRow);
            //            setRegisterUserData(newData);
        },
        []
    );    

    useEffect(() => {
        setLoadingState(true);
        ServiceRequestService.getRegisterUser(authHeader).then(
              filterRegisterUserItems
        ).finally(() => {
            setLoadingState(false);
        });
    }, []);

    const nameCell = useCallback(({ customerContact, serviceRequestNumber }) => {
        return (
            <Link to={`/service-request-details/${serviceRequestNumber}`}>
                {customerContact}
            </Link>
        );
    }, []);

    // leave these code here in case requirement change
    // 07/02/2021 
    const getTableOverrides = useCallback(() => {
        return {
          //  nameColumn: { onCell: nameCell },//,
          //  actionsRevokeColumn: {onCell: actionsRevokeCell}
          //  actionsRejectColumn: {onCell : actionsRejectCell}
            actionsApproveViewColumn: { onCell: actionsApproveCell },
            actionsRejectViewColumn: { onCell: actionsRejectCell }
        };
    }, [actionsApproveCell,actionsRejectCell ]);

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    const override = {
        '@field': {
            labelPosition: 'left'
        },
        userRequestsTable: {
            data: registerUserData,
            filter: searchUserTableFilter
        },
        userRequestsViewTable: {
            data: registerUserViewData,
            filter: searchUserViewTableFilter
        },
        actionsReInstateColumn :{
            renderCell: getProcessButton
        },
        ...getTableOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            actionApproveFunc:  approveDataRow,
            actionRejectFunc : rejectDataRow,
           getProcessButtonFunc: getProcessButton
        }        
    };

    return renderContentFromMetadata(metadata.pageContent, override, resolvers);
}

export default withRouter(UserApprovePage);
